import "./styles.scss";

interface Props {
  label?: string;
  value?: string | number;
}

const FormField: React.FC<Props> = ({ label, value }) => (
  <div className="formField">
    <label>{label}</label>
    <span className={`span`}>{value}</span>
  </div>
);

export default FormField;
