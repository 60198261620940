import { useNavigate } from "react-router-dom";

import { Invoice } from "../../../../../../types/invoice";
import { toDate } from "../../../../../../utils/dates";
import toGBP from "../../../../../../utils/toGBP";
import Table from "../../../../../../components/Table";
import ExpandedProducts from "../ExpandedProducts";

const columns = [
  // {
  //   name: "ID",
  //   selector: (invoice: Invoice) => invoice.id,
  //   sortable: true,
  // },
  {
    name: "Created Date",
    selector: (invoice: Invoice) => toDate(invoice.createdAt),
    sortable: true,
  },
  {
    name: "Due Date",
    selector: (invoice: Invoice) => toDate(invoice.dueDate),
    sortable: true,
  },
  {
    name: "Subscription",
    selector: (invoice: Invoice) => invoice.isSubscription.toString(),
    sortable: true,
  },
  // {
  //   name: "Products",
  //   selector: (invoice: Invoice) => invoice.products.length,
  //   sortable: true,
  // },
  {
    name: "Status",
    selector: (invoice: Invoice) => invoice.status,
    sortable: true,
  },
  {
    name: "🤑",
    selector: (invoice: Invoice) => toGBP(invoice.total),
    sortable: true,
  },
];

interface Props {
  invoices: Invoice[];
}

const InvoiceTable: React.FC<Props> = ({ invoices }) => {
  const navigate = useNavigate();
  return (
    <Table
      columns={columns}
      data={invoices}
      expandableRows
      expandableRowsComponent={ExpandedProducts}
      pagination
      onRowClick={(invoice) => navigate(`/invoices/${invoice.id}`)}
    />
  );
};

export default InvoiceTable;
