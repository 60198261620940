import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import confirmOTP from "../../../../api/auth/confirmOTP";
import { connect } from "react-redux";
import { setIsAuthenticated, setTokens } from "../../../../redux/auth/action";
import { AuthState } from "../../../../redux/auth/types";
import Form from "../../../../components/Form";
import Input from "../../../../components/Form/components/Input";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loading";

interface Props {
  authState: AuthState;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setTokens: (
    accessToken: string,
    refreshToken: string,
    tokenId: string
  ) => void;
}

const ConfirmOTP: React.FC<Props> = ({
  authState,
  setIsAuthenticated,
  setTokens,
}) => {
  const { userRef, session } = authState;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState("");

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    try {
      if (userRef && session) {
        const { accessToken, refreshToken, tokenId } = await confirmOTP({
          userRef,
          session,
          authCode: otp,
        });

        setTokens(accessToken, refreshToken, tokenId);
        setIsAuthenticated(true);

        navigate("/customers");
        toast("Welcome! 👋");
      }
      throw new Error("userRef or session is undefined");
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  return (
    <Form>
      <Input
        name="otp"
        placeholder="OTP"
        onChange={(e) => setOTP(e.target.value)}
      />
      <Button label="Confirm" onClick={onSubmit} />
    </Form>
  );
};

const mapStateToProps = (state) => ({
  authState: state.auth,
});

export default connect(mapStateToProps, { setIsAuthenticated, setTokens })(
  ConfirmOTP
);
