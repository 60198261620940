import { sumBy } from "lodash";
import { Invoice } from "../../types/invoice";
import { InvoicesState } from "./types";

export enum Types {
  SetLoading = "SET_LOADING",
  SetInvoices = "SET_INVOICES",
}

const initialState = {
  loading: false,
  data: [],
  total: 0,
};

type SetLoadingAction = {
  type: Types.SetLoading;
  payload: boolean;
};

type SetInvoicesAction = {
  type: Types.SetInvoices;
  payload: Invoice[];
};



export type Actions = SetLoadingAction | SetInvoicesAction ;

const reducer = (state: InvoicesState = initialState, action: Actions) => {
  switch (action.type) {
    case Types.SetLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case Types.SetInvoices:
      return {
        ...state,
        data: action.payload,
        total: sumBy(action.payload, "total"),
      };



    default:
      return state;
  }
};

export default reducer;
