import { AuthState } from "./types";

export enum Types {
    SetIsAuthenticated = "SET_IS_AUTHENTICATED",
    SetUserRef = "SET_USER_REF",
    SetSession = "SET_SESSION",
    SetTokens = "SET_TOKENS",
    Logout = "Logout",
  }

const initialState = {
    isAuthenticated: false,
    userRef: "",
    session: "",
    accessToken: "",
    refreshToken: "",
    tokenId: ""
}

type SetAuthenticatedStateAction = {
    type: Types.SetIsAuthenticated;
    payload: {
      isAuthenticated: boolean;
    };
};

type SetUserRefAction = {
    type: Types.SetUserRef;
    payload: string;
};

type SetSessionAction = {
    type: Types.SetSession;
    payload: string;
};


type SetTokenIdAction = {
    type: Types.SetTokens;
    payload: {
        accessToken: string;
        refreshToken: string;
        tokenId: string;
    };
};

type LogoutAction = {
    type: Types.Logout;
};

export type Actions =
  | SetAuthenticatedStateAction
  | SetUserRefAction
  | SetSessionAction
  | SetTokenIdAction
  | LogoutAction;


const reducer = (state: AuthState = initialState, action: Actions) => {
    switch (action.type) {
        case Types.SetIsAuthenticated:
            return {
                ...state,
                isAuthenticated: true
            }
        case Types.SetUserRef:
            return {
                ...state,
                userRef: action.payload
            }
        case Types.SetSession:
            return {
                ...state,
                session: action.payload
            }

        case Types.SetTokens:
            return {
                ...state,
                ...action.payload
            }
        case Types.Logout:
            return initialState

        default:
            return state;
    }
}

export default reducer