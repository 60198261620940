import { connect } from "react-redux";
import { InvoicesState } from "../../../../redux/invoices/types";
import Container from "../../../../components/Container";
import InvoiceTable from "./components/InvoiceTable";

interface Props {
  invoices: InvoicesState;
}

const Invoices: React.FC<Props> = ({ invoices }) => {
  return (
    <Container header={<h1>Invoices</h1>}>
      <InvoiceTable invoices={invoices.data} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  invoices: state.invoices,
});

export default connect(mapStateToProps, null)(Invoices);
