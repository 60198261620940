import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';

import login from "../../../../api/auth/login";
import { setSession, setUserRef } from "../../../../redux/auth/action";
import Form from "../../../../components/Form";
import Input from "../../../../components/Form/components/Input";
import "./styles.scss"
import FormButton from "../../../../components/Form/components/FormButton";
import Loading from "../../../../components/Loading";

interface Props {
  setUserRef: (userRef: string) => void
  setSession: (session: string) => void
};

const Login: React.FC<Props>  = ({ 
  setUserRef, 
  setSession 
}) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  })

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setLoading(true)
    try {
      const { userRef, session } =  await login(formData)

      setUserRef(userRef)
      setSession(session)

      navigate("/auth/confirmOTP")
      toast('Confirm your OTP!')

    } catch (err) {
      console.error(err)
      
    } finally {
      setLoading(false)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  if (loading) return <Loading />
  return (
    <Form>
      <Input name="email" placeholder="Email" onChange={onChange}/>
      <Input name="password" placeholder="Password" onChange={onChange} />
      <FormButton label="Login" onClick={onSubmit} />
    </Form>
  );
}

export default connect(null, { setUserRef, setSession })(Login)
  