import { useState } from "react";
import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";
import Container from "../../../../../../components/Container";
import Button from "../../../../../../components/Button";
import Form from "../../../../../../components/Form";
import Input from "../../../../../../components/Form/components/Input";
import Loading from "../../../../../../components/Loading";
import { createCustomer } from "../../../../../../redux/customers/action";
import { Customer } from "../../../../../../types/customer";

interface Props {
  createCustomer: (customer: Partial<Customer>) => void;
}

const CreateCustomer: React.FC<Props> = ({ createCustomer }) => {
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({
    companyName: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [address, setAddress] = useState({
    firstLine: "",
    secondLine: "",
    postcode: "",
    townCity: "",
  });

  const navigate = useNavigate();

  const onDetailsChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  const onAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const onCreate = async () => {
    setLoading(true);
    try {
      await createCustomer({ ...customer, address });
      navigate("/customers");
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container header={<h1>Customers</h1>}>
      <h3>Details</h3>
      <Form twoColumns>
        <Input
          name="companyName"
          label="Company Name"
          onChange={onDetailsChange}
          value={customer.companyName}
        />
        <Input
          name="email"
          label="Email"
          onChange={onDetailsChange}
          value={customer.email}
        />
        <Input
          name="firstName"
          label="First Name"
          onChange={onDetailsChange}
          value={customer.firstName}
        />
        <Input
          name="lastName"
          label="Last Name"
          onChange={onDetailsChange}
          value={customer.lastName}
        />
      </Form>
      <h3>Address</h3>
      <Form twoColumns>
        <Input
          name="firstLine"
          label="Line 1"
          onChange={onAddressChange}
          value={address.firstLine}
        />
        <Input
          name="secondLine"
          label="Line 2"
          onChange={onAddressChange}
          value={address.secondLine}
        />
        <Input
          name="postcode"
          label="Postcode"
          onChange={onAddressChange}
          value={address.postcode}
        />
        <Input
          name="townCity"
          label="Town / City"
          onChange={onAddressChange}
          value={address.townCity}
        />
      </Form>

      {loading ? (
        <Loading center />
      ) : (
        <div className="row-center">
          <Button label="Back" onClick={() => navigate("/customers")} center />
          <Button label="Submit" onClick={onCreate} center />
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  authState: state.auth,
});

export default connect(mapStateToProps, { createCustomer })(CreateCustomer);
