import { Outlet } from "react-router-dom";
import "./styles.scss";

const Auth = () => {
  return (
    <main className="auth">
      <h1>auth</h1>
      <div className="center">
        <Outlet />
      </div>
    </main>
  );
};

export default Auth;
