import Freelancer from "../../../services/Freelancer";


type Response = {
  accessToken: string;
  refreshToken: string;
  tokenId: string;
};

const confirmOTP = async (payload: {
  userRef: string;
  session: string;
  authCode: string;
}): Promise<Response> =>
  Freelancer.request("POST", "/confirmOTP", {
    payload,
  });

export default confirmOTP;
