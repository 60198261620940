import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { CustomersState } from "../../../../../../redux/customers/types";
import { InvoicesState } from "../../../../../../redux/invoices/types";
import Loading from "../../../../../../components/Loading";
import Container from "../../../../../../components/Container";
import "./styles.scss";
import Button from "../../../../../../components/Button";
import FormField from "../../../../../../components/Form/components/FormField";
import toGBP from "../../../../../../utils/toGBP";
import AreYouSureModal from "../../../../../../components/Modal/components/AreYouSureModal";
import {
  deleteInvoice,
  sendInvoice,
} from "../../../../../../redux/invoices/action";

interface Props {
  customers: CustomersState;
  invoices: InvoicesState;
  sendInvoice: (id: string) => void;
  deleteInvoice: (id: string) => void;
}

const ViewInvoice: React.FC<Props> = ({
  customers,
  invoices,
  sendInvoice,
  deleteInvoice,
}) => {
  const navigate = useNavigate();

  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  const invoice = invoices.data.find((invoice) => invoice.id === id);
  const customer = customers.data.find(
    (customer) => customer.id === invoice.customerId
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  const onSend = async () => {
    try {
      await sendInvoice(invoice.id);
    } catch (err) {
      console.error(err);
    }
  };

  const onDelete = async () => {
    try {
      await deleteInvoice(invoice.id);
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) return <Loading center />;
  return (
    <Container header={<h2>{customer?.companyName}</h2>}>
      <h3>Details</h3>

      <div className="twoColumns">
        <FormField label="Status" value={invoice.status} />
        <FormField label="Total" value={toGBP(invoice.total)} />
      </div>

      <h3>Products</h3>
      {invoice.products.map((product, i) => (
        <div key={i} className="twoColumns">
          <FormField label="Description" value={product.description} />
          <FormField label="Price" value={toGBP(product.price)} />
        </div>
      ))}

      <div className="row-center">
        <Button
          type="secondary"
          label="Delete"
          onClick={() => setModalVisible(true)}
        />
        <Button label="Send" onClick={onSend} />
      </div>

      <AreYouSureModal
        visible={modalVisible}
        setVisible={setModalVisible}
        onSuccess={onDelete}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  invoices: state.invoices,
});

export default connect(mapStateToProps, { sendInvoice, deleteInvoice })(
  ViewInvoice
);
