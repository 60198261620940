import { Route, Routes } from "react-router-dom";

import Auth from "../pages/Auth";
import ConfirmOTP from "../pages/Auth/pages/ConfirmOTP";
import Login from "../pages/Auth/pages/Login";
import Home from "../pages/Home";
import Customers from "../pages/Home/pages/Customers";
import Invoices from "../pages/Home/pages/Invoices";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../pages/PageNotFound";
import CreateCustomer from "../pages/Home/pages/Customers/pages/CreateCustomer";
import ViewCustomer from "../pages/Home/pages/Customers/pages/ViewCustomer";
import Dashboard from "../pages/Home/pages/Dashboard";
import ViewInvoice from "../pages/Home/pages/Invoices/pages/ViewInvoice";

const Router = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="" element={<Home />}>
          <Route index path="dashboard" element={<Dashboard />} />
          <Route path="customers" element={<Customers />} />
          <Route path="createCustomer" element={<CreateCustomer />} />
          <Route path="customers/:id" element={<ViewCustomer />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="invoices/:id" element={<ViewInvoice />} />
        </Route>
      </Route>

      <Route path="auth" element={<Auth />}>
        <Route index path="login" element={<Login />} />
        <Route path="confirmOTP" element={<ConfirmOTP />} />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
