import DataTable, { createTheme } from "react-data-table-component";

import "./styles.scss";

interface Column {
  name: string;
  selector: (data: any) => string | number;
  sortable?: boolean;
}
interface Props {
  columns: Column[];
  data: any[];
  onRowClick?: (row: any) => void;
  pagination?: boolean;
  expandableRows?: boolean;
  expandableRowsComponent?: (row: any) => any;
}

createTheme(
  "solarized",
  {
    text: {
      primary: "#fff",
      secondary: "#fff",
    },
    background: {
      default: "#00000000",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#8390FA33",
    },
    action: {
      // button: "rgba(255,255,255,1)",
      // hover: "rgba(255,255,255,1)",
      // disabled: "rgba(255,255,255,1)",
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const handleChange = (state: any) => {
  // You can use setState or dispatch with something like Redux so we can use the retrieved data
  console.log("Selected Rows: ", state.selectedRows);
};

const Table: React.FC<Props> = ({
  columns,
  data,
  onRowClick,
  pagination,
  expandableRows,
  expandableRowsComponent,
}) => {
  return (
    <div className="table">
      <DataTable
        columns={columns}
        data={data}
        onRowClicked={onRowClick}
        pagination={pagination}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
        theme="solarized"
        // selectableRows
        // Selected={handleChange}
      />
    </div>
  );
};

export default Table;
