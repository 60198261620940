import { connect } from "react-redux";
import { InvoicesState } from "../../../../redux/invoices/types";
import Container from "../../../../components/Container";
import { CustomersState } from "../../../../redux/customers/types";
import Loading from "../../../../components/Loading";
import toGBP from "../../../../utils/toGBP";
import "./styles.scss";

interface Props {
  customers: CustomersState;
  invoices: InvoicesState;
}

const Dashboard: React.FC<Props> = ({ customers, invoices }) => {
  if (customers.loading || invoices.loading) return <Loading center />;
  return (
    <Container>
      <h1>Dash</h1>

      <div className="dashBox">
        <div className="value">{toGBP(invoices.total)}</div>
        <div className="description">Profit</div>
      </div>

      <div className="dashBox">
        <div className="value">{customers.count}</div>
        <div className="description">Customers</div>
      </div>

      <div className="dashBox">
        <div className="value">{toGBP(customers.monthlySubscription)}/mo</div>
        <div className="description">Subscriptions</div>
      </div>

      <span className="dankText"></span>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  invoices: state.invoices,
});

export default connect(mapStateToProps, null)(Dashboard);
