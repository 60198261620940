const parseFormValue = (value: string, type: any) => {
  return type === "number"
    ? Number(value)
    : type === "checkbox"
    ? value === "false"
      ? true
      : false
    : value;
};

export default parseFormValue;
