import "./styles.scss"

interface Props {
    twoColumns?: boolean;
    children: React.ReactNode
};

  
const Form: React.FC<Props> = ({ twoColumns, children }) => {

    return (
        <form className={`form ${twoColumns ? "twoColumns" : ""}`}> 
            {children}
        </form>
    )
}

export default Form