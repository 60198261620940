import "./styles.scss";

interface Props {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const Tabs: React.FC<Props> = ({ tabs, activeTab, setActiveTab }) => (
  <div className="tabs">
    {tabs.map((tab) => (
      <button
        key={tab}
        className={`tab ${tab === activeTab ? "active" : ""}`}
        onClick={() => setActiveTab(tab)}
      >
        {tab}
      </button>
    ))}
  </div>
);

export default Tabs;
