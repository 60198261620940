import { Link } from "react-router-dom";
import { connect } from "react-redux";

import "./styles.scss";
import Button from "../Button";
import { logout } from "../../redux/auth/action";

interface Props {
  logout: () => void;
}

const Nav: React.FC<Props> = ({ logout }) => {
  return (
    <nav>
      <Link to="/dashboard">Dash</Link>
      <Link to="customers">Customers</Link>
      <Link to="invoices">Invoices</Link>
      <Button label="Logout" onClick={logout} />
    </nav>
  );
};

export default connect(null, { logout })(Nav);
