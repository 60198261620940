import "./styles.scss";

interface Props {
  visible: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<Props> = ({ visible, children }) => {
  if (visible)
    return (
      <div className="modal">
        <div className="modalContainer">{children}</div>
      </div>
    );
  return <></>;
};

export default Modal;
