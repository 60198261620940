import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducer'
import StateLoader from './StateLoader'


const middleware = [thunk]

const store = createStore(
    rootReducer,
    StateLoader.loadState(),
    composeWithDevTools(applyMiddleware(...middleware))
)

store.subscribe(() => {
    StateLoader.saveState(store.getState())
})


export default store