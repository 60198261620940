import { toast } from "react-hot-toast";

import { Types } from "./reducer";
import { ERROR_MESSAGE } from "../../constants/errors";
import Freelancer from "../../services/Freelancer";

export const fetchCustomers = () => async (dispatch, getState) => {
    dispatch({
      type: Types.SetLoading,
      payload: true,
    });

    try {
      const { auth } = getState();
      const customers = await Freelancer.request("GET", "/customers", {
        authState: auth,
      });
    
      dispatch({
        type: Types.SetCustomers,
        payload: customers
      });
    } catch (err) {
      console.error(err);
      toast(ERROR_MESSAGE);
    } finally {
      dispatch({
        type: Types.SetLoading,
        payload: false,
      });
    }
  };


export const createCustomer = (customer) => async (dispatch, getState) => {

  try {
    const { auth, customers } = getState();
    const newCustomer = await Freelancer.request("POST", `/customers`, {
      authState: auth,
      payload: customer,
      toast: true,
    });

    dispatch({
      type: Types.SetCustomers,
      payload: [...customers.data, newCustomer],
    });
  } catch (err) {
    console.error(err);
    toast(ERROR_MESSAGE);
  }
};

export const deleteCustomer = (id) => async (dispatch, getState) => {
    
  try {
    const { auth, customers } = getState();
    await Freelancer.request("DELETE", `/customers/${id}`, {
      authState: auth,
      toast: true,
    });

    dispatch({
      type: Types.SetCustomers,
      payload: customers.data.filter(customer => customer.id !== id),
    });
  } catch (err) {
    console.error(err);
    toast(ERROR_MESSAGE);
  } 
};

export const updateCustomer = (customer) => async (dispatch, getState) => {
    
  try {
    const { auth, customers } = getState();
    const updatedCustomer = await Freelancer.request("PUT", `/customers/${customer.id}`, {
      authState: auth,
      payload: customer,
      toast: true,
    });

    dispatch({
      type: Types.SetCustomers,
      payload: customers.data.map((customer) => customer.id === updatedCustomer.id ? updatedCustomer : customer),
    });
  } catch (err) {
    console.error(err);
    toast(ERROR_MESSAGE);
  } 
};
