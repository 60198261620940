import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Outlet, useParams } from "react-router-dom";

import { Customer } from "../../../../../../types/customer";

import { CustomersState } from "../../../../../../redux/customers/types";
import { InvoicesState } from "../../../../../../redux/invoices/types";
import { Invoice } from "../../../../../../types/invoice";
import Loading from "../../../../../../components/Loading";
import Container from "../../../../../../components/Container";
import "./styles.scss";
import Details from "./components/Details";
import Invoices from "./components/Invoices";
import Tabs from "../../../../../../components/Tabs";
import { AuthState } from "../../../../../../redux/auth/types";

interface Props {
  authState: AuthState;
  customers: CustomersState;
  invoices: InvoicesState;
}

const ViewCustomer: React.FC<Props> = ({ authState, customers, invoices }) => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<string>("Details");

  const customer = customers.data.find((customer) => customer.id === id);
  const customerInvoices = invoices.data.filter(
    (invoice) => invoice.customerId === id
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) return <Loading center />;
  return (
    <Container header={<h2>{customer?.companyName}</h2>}>
      {/* <Link to="/home">Home</Link>
      <Link to="/users">Users</Link> */}

      {/* <Outlet  /> */}

      <Tabs
        tabs={["Details", "Invoices"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {customer && customerInvoices && (
        <>
          {activeTab === "Details" && <Details customer={customer} />}

          {activeTab === "Invoices" && (
            <Invoices customer={customer} invoices={customerInvoices} />
          )}
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  customers: state.customers,
  invoices: state.invoices,
});

export default connect(mapStateToProps, null)(ViewCustomer);
