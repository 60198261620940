import { sumBy } from "lodash";
import { Customer } from "../../types/customer";
import { CustomersState } from "./types";

export enum Types {
  SetLoading = "SET_LOADING",
  SetCustomers = "SET_CUSTOMERS",
}

const initialState = {
  loading: false,
  data: [],
  count: 0,
  monthlySubscription: 0,
};

type SetLoadingAction = {
  type: Types.SetLoading;
  payload: boolean;
};

type SetCustomersAction = {
  type: Types.SetCustomers;
  payload: Customer[]
};

export type Actions = SetLoadingAction | SetCustomersAction;

const reducer = (state: CustomersState = initialState, action: Actions) => {
  switch (action.type) {
    case Types.SetLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case Types.SetCustomers:
      return {
        ...state,
        data: action.payload,
        count: action.payload.length,
        monthlySubscription: sumBy(action.payload, "subscription.total")
      };
    default:
      return state;
  }
};

export default reducer;
