import { connect } from "react-redux";

import { CustomersState } from "../../../../redux/customers/types";
import { useNavigate } from "react-router-dom";
import Container from "../../../../components/Container";
import Button from "../../../../components/Button";
import CustomerTable from "./components/CustomerTable";

interface Props {
  customers: CustomersState;
}

const Customers: React.FC<Props> = ({ customers }) => {
  const navigate = useNavigate();

  return (
    <Container header={<h1>Customers</h1>}>
      <CustomerTable customers={customers.data} />
      <Button
        label="Create Customer"
        onClick={() => navigate("/createCustomer")}
        center
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers,
});

export default connect(mapStateToProps, null)(Customers);
