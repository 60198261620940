import { toast } from "react-hot-toast";

import { Types } from "./reducer";
import { ERROR_MESSAGE } from "../../constants/errors";
import Freelancer from "../../services/Freelancer";

export const fetchInvoices = () => async (dispatch, getState) => {

    dispatch({
      type: Types.SetLoading,
      payload: true,
    });

    try {
      const { auth } = getState();
      const invoices = await Freelancer.request("GET", "/invoices", {
        authState: auth,
      });

      dispatch({
        type: Types.SetInvoices,
        payload: invoices,
      });
    } catch (err) {
      console.error(err);
      toast(ERROR_MESSAGE);
    } finally {
      dispatch({
        type: Types.SetLoading,
        payload: false,
      });
    }
  };



export const createInvoice = (invoice) => async (dispatch, getState) => {
    
  try {
    const { auth, invoices } = getState();
    console.log(invoice)
    const newInvoice = Freelancer.request("POST", "/invoices", {
      authState: auth,
      payload: {
        invoice
      },
      toast: true,
    });

    dispatch({
      type: Types.SetInvoices,
      payload: [...invoices.data, newInvoice],
    });
  } catch (err) {
    console.error(err);
    toast(ERROR_MESSAGE);
  } 
};


export const deleteInvoice = (id) => async (dispatch, getState) => {
    
  try {
    const { auth, invoices } = getState();
    await Freelancer.request("DELETE", `/invoices/${id}`, {
      authState: auth,
      toast: true,
    });

    dispatch({
      type: Types.SetInvoices,
      payload: invoices.data.filter(invoice => invoice.id !== id),
    });
  } catch (err) {
    console.error(err);
    toast(ERROR_MESSAGE);
  } 
};

export const sendInvoice = (id) => async (dispatch, getState) => {
    
  try {
    const { auth, invoices } = getState();
    const { invoice: updatedInvoice } = await Freelancer.request("POST",  `/sendInvoice/`, {
      authState: auth,
      payload: {
        invoiceId: id
      },
      toast: true,
    });


    dispatch({
      type: Types.SetInvoices,
      payload: invoices.data.map((invoice) => invoice.id === updatedInvoice.id ? updatedInvoice : invoice),
    });
  } catch (err) {
    console.error(err);
    toast(ERROR_MESSAGE);
  } 
};