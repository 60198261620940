

import {DateTime} from 'luxon'

export const toDateTime = (date: Date | string) => {
    return DateTime.fromISO(date.toString()).toFormat('dd/MM/yy')
}


export const toDate = (date: Date | string) => {
    return DateTime.fromISO(date.toString()).toFormat('dd/MM/yy')
}
