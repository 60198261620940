import "./styles.scss";

interface Props {
  header?: React.ReactNode;
  children: React.ReactNode;
}

const Container: React.FC<Props> = ({ header, children }) => (
  <div className="container">
    {header && <div className="header">{header}</div>}
    {children}
  </div>
);

export default Container;
