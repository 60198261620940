import "./styles.scss";

interface Props {
  center?: boolean;
}

const Loading: React.FC<Props> = ({ center }) => {
  if (center) {
    return (
      <div className="loading-center">
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
};

export default Loading;
