import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./styles.scss";
import Form from "../../../../../../../../components/Form";
import Input from "../../../../../../../../components/Form/components/Input";
import { Customer } from "../../../../../../../../types/customer";
import Button from "../../../../../../../../components/Button";
import AreYouSureModal from "../../../../../../../../components/Modal/components/AreYouSureModal";
import parseFormValue from "../../../../../../../../services/Freelancer/utils/parseFormValue";
import { connect } from "react-redux";
import {
  deleteCustomer,
  updateCustomer,
} from "../../../../../../../../redux/customers/action";

interface Props {
  customer: Customer;
  deleteCustomer: (id: string) => void;
  updateCustomer: (customer: Customer) => void;
}

const Details: React.FC<Props> = ({
  customer,
  deleteCustomer,
  updateCustomer,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [customerForm, setCustomerForm] = useState({ ...customer });
  const navigate = useNavigate();

  useEffect(() => {
    setCustomerForm({ ...customer });
  }, [customer]);

  const onAddressChange = (e) => {
    const { name, value, type } = e.target;
    setCustomerForm({
      ...customerForm,
      address: {
        ...customerForm.address,
        [name]: parseFormValue(value, type),
      },
    });
  };

  const onDetailsChange = (e) => {
    const { name, value, type } = e.target;
    setCustomerForm({
      ...customerForm,
      [name]: parseFormValue(value, type),
    });
  };

  const onSubChange = (e, productIndex: number) => {
    const { name, value, type } = e.target;
    customerForm.subscription.products[productIndex][name] = parseFormValue(
      value,
      type
    );
    setCustomerForm({ ...customerForm });
  };

  const removeProduct = () => {
    const products = customerForm.subscription.products.slice(0, -1);
    setCustomerForm({
      ...customerForm,
      subscription: { ...customerForm.subscription, products },
    });
  };

  const addProduct = () => {
    const newProduct = {
      description: "",
      price: undefined,
    };
    if (!customerForm.subscription)
      setCustomerForm({
        ...customerForm,
        subscription: { products: [newProduct] },
      });
    else {
      const products = customerForm.subscription.products.concat(newProduct);
      setCustomerForm({
        ...customerForm,
        subscription: { ...customerForm.subscription, products },
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteCustomer(customer.id);
      navigate("/customers");
    } catch (err) {
      console.error(err);
    }
  };

  const onUpdate = async () => {
    try {
      await updateCustomer(customerForm);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <h3>Details</h3>
      <Form twoColumns>
        <Input
          name="companyName"
          label="Company Name"
          onChange={onDetailsChange}
          value={customerForm.companyName}
        />
        <Input
          name="email"
          label="Email"
          onChange={onDetailsChange}
          value={customerForm.email}
        />
        <Input
          name="firstName"
          label="First Name"
          onChange={onDetailsChange}
          value={customerForm.firstName}
        />
        <Input
          name="lastName"
          label="Last Name"
          onChange={onDetailsChange}
          value={customerForm.lastName}
        />
      </Form>
      <h3>Address</h3>
      <Form twoColumns>
        <Input
          name="firstLine"
          label="Line 1"
          onChange={onAddressChange}
          value={customerForm.address.firstLine}
        />
        <Input
          name="secondLine"
          label="Line 2"
          onChange={onAddressChange}
          value={customerForm.address.secondLine}
        />
        <Input
          name="postcode"
          label="Postcode"
          onChange={onAddressChange}
          value={customerForm.address.postcode}
        />
        <Input
          name="townCity"
          label="Town / City"
          onChange={onAddressChange}
          value={customerForm.address.townCity}
        />
      </Form>

      <div className="header-and-button">
        <h3>Subscription</h3>
        <div>
          <Button type="secondary" label="-" onClick={() => removeProduct()} />
          <Button label="+" onClick={() => addProduct()} />
        </div>
      </div>

      {customerForm.subscription &&
        customerForm.subscription.products.map((product, i) => (
          <Form key={i} twoColumns>
            <Input
              name="description"
              label="Description"
              value={product.description}
              onChange={(e) => onSubChange(e, i)}
            />
            <Input
              name="price"
              label="Price (£)"
              value={product.price}
              type="number"
              onChange={(e) => onSubChange(e, i)}
            />
          </Form>
        ))}

      <div className="row-center">
        <Button
          type="secondary"
          label="Delete"
          onClick={() => setModalVisible(true)}
        />
        <Button label="Update" onClick={onUpdate} />
      </div>
      <AreYouSureModal
        visible={modalVisible}
        setVisible={setModalVisible}
        onSuccess={onDelete}
      />
    </>
  );
};

export default connect(null, { deleteCustomer, updateCustomer })(Details);
