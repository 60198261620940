import { useState } from "react";
import { connect } from "react-redux";

import Button from "../../../../../../../../components/Button";
import { Invoice, InvoiceStatus } from "../../../../../../../../types/invoice";
import Form from "../../../../../../../../components/Form";
import Input from "../../../../../../../../components/Form/components/Input";
import { Customer } from "../../../../../../../../types/customer";
import parseFormValue from "../../../../../../../../services/Freelancer/utils/parseFormValue";

import InvoiceTable from "../../../../../Invoices/components/InvoiceTable";
import { createInvoice } from "../../../../../../../../redux/invoices/action";
import "./styles.scss";

interface Props {
  customer: Partial<Customer>;
  invoices: Invoice[];
  createInvoice: (invoice: Partial<Invoice>) => void;
}

const Invoices: React.FC<Props> = ({ customer, invoices, createInvoice }) => {
  const [invoice, setInvoice] = useState({
    customerId: customer.id,
    products: [
      {
        description: "",
        price: 0,
      },
    ],
    isSubscription: false,
    status: InvoiceStatus.CREATED,
  });
  const [toggleCreateInvoice, setToggleCreateInvoice] = useState(false);

  const onPostInvoice = async () => {
    try {
      await createInvoice(invoice);
      // navigate(`/invoices/${res.id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const onChange = (e, productIndex?: number) => {
    const { name, value, type } = e.target;
    if (productIndex !== undefined) {
      invoice.products[productIndex][name] = parseFormValue(value, type);
      setInvoice({ ...invoice });
    } else {
      setInvoice({
        ...invoice,
        [name]: parseFormValue(value, type),
      });
    }
  };

  const removeProduct = () => {
    const products = invoice.products.slice(0, -1);
    setInvoice({ ...invoice, products });
  };

  const addProduct = () => {
    const products = invoice.products.concat({
      description: "",
      price: 0,
    });
    setInvoice({ ...invoice, products });
  };

  return (
    <>
      {toggleCreateInvoice ? (
        <>
          <div className="header-and-button">
            <h3>Create Invoice</h3>
            <div>
              <Button
                type="secondary"
                label="-"
                onClick={() => removeProduct()}
              />
              <Button label="+" onClick={() => addProduct()} />
            </div>
          </div>

          {invoice.products.map((product, i) => (
            <Form key={i} twoColumns>
              <Input
                name="description"
                label="Description"
                value={product.description}
                onChange={(e) => onChange(e, i)}
              />
              <Input
                name="price"
                label="Price (£)"
                value={product.price}
                type="number"
                onChange={(e) => onChange(e, i)}
              />
            </Form>
          ))}

          <div className="row-center">
            <Button
              type="secondary"
              label="Cancel"
              onClick={() => setToggleCreateInvoice(false)}
            />
            <Button label="Submit" onClick={onPostInvoice} />
          </div>
        </>
      ) : (
        <>
          <h3>Invoices</h3>
          <InvoiceTable invoices={invoices} />
          <Button
            center
            label="Create Invoice"
            onClick={() => setToggleCreateInvoice(true)}
          />
        </>
      )}
    </>
  );
};

export default connect(null, { createInvoice })(Invoices);
