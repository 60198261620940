import Modal from "../..";
import Button from "../../../Button";
import "./styles.scss";

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: () => void;
}

const AreYouSureModal: React.FC<Props> = ({
  visible,
  setVisible,
  onSuccess,
}) => (
  <Modal visible={visible}>
    <span>Are you sure?</span>
    <div className="modalButton">
      <Button type="secondary" label="No" onClick={() => setVisible(false)} />
      <Button
        label="Yes"
        onClick={() => {
          setVisible(true);
          onSuccess();
        }}
      />
    </div>
  </Modal>
);

export default AreYouSureModal;
