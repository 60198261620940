import { Product } from "./product";

export interface Invoice {
  id: string;
  customerId: string;
  products: Product[];
  total: number;
  isSubscription: boolean;
  status: InvoiceStatus;
  createdAt: string | Date;
  dueDate: string | Date;
}

export enum InvoiceStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  OVERDUE = "OVERDUE",
  PAID = "PAID",
}