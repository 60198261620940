import { Types } from "./reducer";


export const setIsAuthenticated = (isAuthenticated: boolean) => async (dispatch: any) => {

    dispatch({
        type: Types.SetIsAuthenticated,
        payload: isAuthenticated
    })
};


export const setSession = (session: string) => async (dispatch: any) => {

    dispatch({
        type: Types.SetSession,
        payload: session
    })
};


export const setUserRef = (userRef: string) => async (dispatch: any) => {

    dispatch({
        type: Types.SetUserRef,
        payload: userRef
    })
};

export const setTokens = ( accessToken: string, refreshToken: string, tokenId: string) => async (dispatch: any) => {

    dispatch({
        type: Types.SetTokens,
        payload: {
            accessToken,
            refreshToken,
            tokenId
        }
    })
};


export const logout = () => async (dispatch: any) => {

    localStorage.removeItem("freelancer");
    dispatch({
        type: Types.Logout
    })
}