import { useNavigate } from "react-router-dom";

import { Customer } from "../../../../../../types/customer";
import Table from "../../../../../../components/Table";

const columns = [
  {
    name: "Name",
    selector: (customer: Customer) =>
      `${customer.firstName} ${customer.lastName}`,
    sortable: true,
  },
  {
    name: "Company",
    selector: (customer: Customer) => customer.companyName,
    sortable: true,
  },
  {
    name: "Email",
    selector: (customer: Customer) => customer.email,
    sortable: true,
  },
];

interface Props {
  customers: Customer[];
}

const CustomerTable: React.FC<Props> = ({ customers }) => {
  const navigate = useNavigate();

  return (
    <Table
      columns={columns}
      data={customers}
      onRowClick={(customer) => navigate(`/customers/${customer.id}`)}
    />
  );
};

export default CustomerTable;
