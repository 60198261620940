import { Invoice } from "../../../../../../types/invoice";
import toGBP from "../../../../../../utils/toGBP";
import "./styles.scss";

interface ExpandableProps {
  data: Invoice;
}

const ExpandedProducts: React.FC<ExpandableProps> = ({ data }) => {
  return (
    <div className="expandable">
      {data.products.map((product, i) => (
        <div key={i} className="row">
          <div className="col">
            <span>{product.description}</span>
          </div>
          <div className="col">
            <span>{toGBP(product.price)}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpandedProducts;
