class StateLoader {

    static loadState() {
        try {
            let serializedState = localStorage.getItem("freelancer");

            if (serializedState === null) {
                return this.initializeState();
            }
            return JSON.parse(serializedState);
        }
        catch (err) {
            return this.initializeState();
        }
    }

    static saveState(state) {
        try {
            let serializedState = JSON.stringify(state);
            localStorage.setItem("freelancer", serializedState);

        }
        catch (err) {
        }
    }

    static initializeState() {
        return {
            //state object
            
        }
    };
}

export default StateLoader