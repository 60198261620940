import { Outlet } from "react-router-dom";
import Nav from "../../components/Nav";
import "./styles.scss";
import { fetchCustomers } from "../../redux/customers/action";
import { connect } from "react-redux";
import { AuthState } from "../../redux/auth/types";
import { useEffect } from "react";
import { fetchInvoices } from "../../redux/invoices/action";

interface Props {
  authState: AuthState;
  fetchCustomers: () => void;
  fetchInvoices: () => void;
}

const Home: React.FC<Props> = ({
  authState,
  fetchCustomers,
  fetchInvoices,
}) => {
  useEffect(() => {
    fetchCustomers();
    fetchInvoices();
  }, []);

  return (
    <main className="home">
      <Nav />
      <Outlet />
    </main>
  );
};

const mapStateToProps = (state) => ({
  authState: state.auth,
});

export default connect(mapStateToProps, { fetchCustomers, fetchInvoices })(
  Home
);
