import { CombinedState, combineReducers } from "redux";

import auth from './auth/reducer'
import customers from './customers/reducer'
import invoices from './invoices/reducer'

import { AuthState } from "./auth/types";
import { CustomersState } from "./customers/types";
import { InvoicesState } from "./invoices/types";


export interface RootState extends CombinedState<{
    auth: AuthState;
    customers: CustomersState;
    invoices: InvoicesState;
}> {}
  
  
export default combineReducers({
    auth,
    customers,
    invoices
})