import { Dictionary } from "../../../../types/general";


const encodeQueryStringParameters = (parameters: Dictionary) =>
  Object.entries(parameters)
    .filter(([, value]) => value !== undefined && value !== "")
    .map(
      ([key, value]) =>
        value !== undefined &&
        value !== "" &&
        `${key}=${encodeURIComponent(value)}`
    )
    .join("&");

export default encodeQueryStringParameters;
