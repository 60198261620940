import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

import store from "./redux/store";
import Router from "./router";
import "./styles/global.scss";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Toaster position="bottom-center" />
        <Router />
      </div>
    </Provider>
  );
}

export default App;
