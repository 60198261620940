import Freelancer from "../../../services/Freelancer";


type Response = {
  session: string;
  userRef: string;
};

const login = async (payload: {
  email: string;
  password: string;
}): Promise<Response> =>
  Freelancer.request("POST", "/login", {
    payload,
  });

export default login;
